
import { defineComponent, onMounted, onUnmounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import store from "@/store";
import variables from "@/router/api";

export default defineComponent({
  name: "individual-client-personal-information",
  components: {},
  props: {
    cartItemId: { type: String, required: true },
  },
  setup(props) {
    const router = useRouter();
    const iframe = ref(null);
    const loadIframe = ref(false);
    const loadFirstFrame = ref(true);
    const spinner = ref(true);
    const showBackButton = ref(false);
    const defaultText = ref("Loading KYC verification, please wait...");
    const loadCounter = ref(0);
    const maxLoads = 2;

    interface User {
      emailAddress: string;
    }

    const user = computed<User>(() => {
      return store.getters.currentUser;
    });

    const url = ref(
      `https://identity.dojah.io/?widget_id=67c89140adc6e8050f75c1be&user_data[email]=${user.value.emailAddress}`
    );

    const initiateKYC = async () => {
      loadFirstFrame.value = false;

      setTimeout(() => {
        if (url.value) {
          defaultText.value = "Starting KYC verification module...";
          // loadIframe.value = true;
          window.open(url.value, "_blank");
        }

        if (!url.value) {
          defaultText.value = "Unable to load KYC verification module.";
          showBackButton.value = true;
          spinner.value = false;
        }
      }, 3000);
    };

    const triggerNextAction = () => {
      if (props.cartItemId == undefined) {
        const cartCount = store.getters.getCartCount;
        let route = "";

        if (store.getters.currentUser.role == "cooperative-admin-user") {
          route = cartCount > 0 ? "/cooperative/cart" : "/cooperative/policies";
        } else {
          route =
            cartCount > 0 ? "/insurance-packages/cart" : "/insurance-packages";
        }

        router.push({
          path: route,
        });
      }

      if (props.cartItemId != undefined) {
        router.push({
          path: "/insurance-packages/cart/debit-note/" + props.cartItemId,
        });
      }
    };

    const handleMessage = (event) => {
      const eventType = event.data?.type;

      if (
        event.origin === "https://wealthpro.lbn.com.ng" &&
        event.data === "ibn-redirect"
      ) {
        triggerNextAction();
      }

      if (
        eventType === "connect.widget.close" ||
        eventType === "connect.account.error"
      ) {
        variables.toastAlert(event.data?.response?.message, "error");
        router.push("/dashboard");
      }
    };

    const onLoad = () => {
      loadCounter.value++;

      if (loadCounter.value >= maxLoads) {
        handleMessage({
          origin: "https://wealthpro.lbn.com.ng",
          data: "ibn-redirect",
        });
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("KYC Verification", ["Profile"]);
      window.addEventListener("message", handleMessage);
    });

    onUnmounted(() => {
      window.removeEventListener("message", handleMessage);
    });

    return {
      triggerNextAction,
      onLoad,
      initiateKYC,
      loadFirstFrame,
      loadIframe,
      url,
      iframe,
      defaultText,
      showBackButton,
      spinner,
    };
  },
  created() {
    //Set page title
    document.title = "KYC Verification | " + process.env.VUE_APP_TITLE;
  },
});
